import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { SearchResult } from '../models/request/search-result.model';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BalletTerm } from '../models/ballet-term';

@Injectable({
    providedIn: 'root'
})
export class SearchService {
    public results: Subject<BalletTerm[]> = new Subject();

    public constructor(public http: HttpClient) {
    }

    public getResults(searchQuery: string): Observable<BalletTerm[]> {
        return this.http.get<BalletTerm[]>('https://us-central1-my-ballet-dictionary-dev.cloudfunctions.net/searchTerms', {
            params: {
                name: searchQuery
            }
        }).pipe(
            tap((results) => this.results.next(results)),
            tap(console.log)
        );
    }

    public getTerm(id: number) {
        return this.http.get<BalletTerm>('http://localhost:3000/term');
    }
}
