import { Component } from '@angular/core';
import { SearchService } from '../../shared/services/search.service';

@Component({
    selector: 'results',
    styleUrls: [
        './results.component.scss'
    ],
    templateUrl: './results.component.html'
})
export class ResultsComponent {
    constructor(public searchService: SearchService) {
    }

    public goToTerm(term: any) {

    }
}
