import { Component, ViewEncapsulation } from '@angular/core';

@Component({
	selector: 'app',
	encapsulation: ViewEncapsulation.None,
	styleUrls: [
		'./app.component.scss'
	],
	templateUrl: './app.component.html'
})

export class AppComponent {

}
