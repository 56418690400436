import { Component, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SearchService } from '../../shared/services/search.service';

@Component({
  selector: 'app-details',
  encapsulation: ViewEncapsulation.None,
  styleUrls: [
    './details.component.scss'
  ],
  templateUrl: './details.component.html'
})
export class DetailsComponent {
	public term = this.searchService.getTerm(this.route.snapshot.params.id);

	public constructor(public route: ActivatedRoute, private searchService: SearchService) {
	}


}
