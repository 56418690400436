import { Component, ViewEncapsulation } from '@angular/core';
import { SearchService } from '../../shared/services/search.service';

@Component({
  selector: 'search',
  styleUrls: [
    './search.component.scss'
  ],
  templateUrl: './search.component.html'
})
export class SearchComponent {
  public searchText;

  constructor(public searchService: SearchService) {
  }

  public getResults(query: string) {
  	this.searchService.getResults(query).subscribe();
  }

}
